// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    this.setNoneSelected();
  }

  connect() {
    console.log("Hello, Fee Creation!", this.element)
  }

  static targets = [ "feeCard", "placeholderCard", "addFeeCard", "feeRow" ]

  select(event) {
    let row = event.currentTarget; // table row
    let feeId = row.getAttribute("data-id");
    console.log('select ', feeId);

    this.removeSelectedClassFromChildren(row.parentNode);
    this.addFeeCardTarget.classList.add('is-hidden');

    if (this.selectedFee === feeId) {
      this.setNoneSelected(); // we are deselecting it
      console.log('none ', this.selectedFee);
      this.placeholderCardTarget.classList.remove('is-hidden');
      this.feeCardTargets.forEach(target => {
        target.classList.add('is-hidden');
      })
    } else {
      this.selectedFee = feeId;
      console.log('something ', this.selectedFee);
      row.classList.add('is-selected');
      this.placeholderCardTarget.classList.add('is-hidden');
      this.feeCardTargets.forEach(target => {
        target.classList.add('is-hidden');
        if (target.getAttribute("data-id") === feeId) {
          target.classList.remove('is-hidden');
        }
      });
    }
  }

  validate() {

  }

  delete(event) {
    let feeId = event.target.getAttribute("data-id");
    let deleteForm = document.getElementById('delete-form-' + feeId);
    deleteForm.submit();
  }

  update(event) {
    let feeId = event.target.getAttribute("data-id");
    let updateForm = document.getElementById('update-form-' + feeId);
    updateForm.submit();
  }

  add() {
    this.setNoneSelected(); // we are deselecting anything selected
    this.placeholderCardTarget.classList.add('is-hidden');
    this.feeCardTargets.forEach(target => {
      target.classList.add('is-hidden');
    });
    this.deselectTableRows();
    this.addFeeCardTarget.classList.remove('is-hidden');
  }

  setNoneSelected() {
    this.selectedFee = 'none';
    console.log('selected: ', this.selectedFee);
  }

  removeSelectedClassFromChildren(el) {
    console.log('removing!');
    for (let i = 0; i < el.children.length; i++) {
      console.log()
      el.children[i].classList.remove('is-selected');
    }
  }

  deselectTableRows() {
    this.feeRowTargets.forEach(target => {
      target.classList.remove('is-selected');
    });
  }
}
