
import { Controller } from "stimulus";

export default class extends Controller {

  // this controller is for when the project is set, e.g. there's no select for the project
  // it is meant to be used for adding investors from the project page/workflow

  initialize() {
    this.projectTotalAmount = 0.0;
    this.unitPurchasePrice = 0.0;
    this.totalNumberOfUnits = 0.0;
    this.purchasedUnits = 0.0;
    this.ownershipPercentage = 0.0;
    this.amountPaid = 0.0;

    this.currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    this.numberFormatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 3
    });

  }

  connect() {
    console.log("Hello, Investor Creation!", this.element)
  }

  static targets = [ "project", "ownership", "investorUnits", "paid"];


  calculate() {
    this.purchasedUnits = this.investorUnitsTarget.value;
    console.log('val ', this.purchasedUnits);

    let totalAmount = this.projectTarget.getAttribute("data-amount");
    let unitPrice = this.projectTarget.getAttribute("data-price");
    let totalNumberOfUnits = this.projectTarget.getAttribute("data-units");

    //console.log(totalAmount);
    //console.log(unitPrice);
    //console.log(totalNumberOfUnits);

    let ratio = (this.purchasedUnits / totalNumberOfUnits);
    this.ownershipPercentage = ratio * 100.0;
    this.ownershipTarget.textContent = this.numberFormatter.format(this.ownershipPercentage) + '%';
    this.amountPaid = ratio * totalAmount;
    this.paidTarget.textContent = this.currencyFormatter.format(this.amountPaid);
  }
}