// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
  }

  connect() {
    console.log("Hello, Payment Creation!", this.element)
  }

  select(event) {

    let paymentMethodSelect = event.currentTarget; // table row
    let distributionId = paymentMethodSelect.getAttribute("data-id");
    let distributionUrl = paymentMethodSelect.getAttribute("data-url");
    let selectedPaymentMethodId = paymentMethodSelect.value;
    let statusDisplay = paymentMethodSelect.closest("td").querySelector("span");
    //console.log('span is ', statusDisplay);
    //console.log('url is ', distributionUrl);
    //console.log('dist is ', distributionId);
    //console.log('value is ', selectedPaymentMethodId);

    const csrfToken = document.querySelector("[name='csrf-token']").content;

    fetch(distributionUrl, {
      method: "PATCH",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ "investor_distribution": {
          "payment_method_id": selectedPaymentMethodId
        }}
      )
    }).then(response => response.json())
      .then(data => this.setStatusDisplay(statusDisplay, data));
  }

  setStatusDisplay(statusDisplay, data) {

    if (data.success == true) {
      statusDisplay.classList.remove('has-text-danger');
      statusDisplay.classList.add('has-text-success');
      statusDisplay.innerText = `${data.name} changed to ${data.payment_method_name}`;
    } else { // error
      statusDisplay.classList.remove('has-text-success');
      statusDisplay.classList.add('has-text-danger');
      statusDisplay.innerText = `Error changing payment method for ${data.name}`;
    }

  }

}
