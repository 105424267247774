// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
     this.currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    this.adjustmentIsValid = true;
    this.rolloverIsValid = true;
    this.payoutIsValid = true;
    this.adjustmentValue = 0.00;
    this.rolloverValue = 0.00;
    this.payoutValue = 0.00;
    this.payoutDisplayTarget;
  }

  connect() {
    console.log("Hello, Investor Distribution Update!", this.element);
  }

  static targets = [ "adjustment", "adjustmentDisplay", "rollover", "rolloverDisplay", "payoutDisplay", "net", "save" ]

  adjustment() {
    console.log('it');
    //console.log(this.adjustmentTarget.value);
    let adjustmentAmount = this.adjustmentTarget.value.trim(); //.replace(/[^\d.]/g, '');
    //let adjustmentAmount = Number.parseFloat(trimmedAdjustment);
    console.log(adjustmentAmount);
    if (adjustmentAmount.length == 0) {
      console.log('just white space');
      this.adjustmentIsValid = true;
      this.adjustmentValue = 0.00;
      this.adjustmentDisplayTarget.textContent = this.currencyFormatter.format(adjustmentAmount);
    } else if (isNaN(adjustmentAmount)) {
      //this.adjustmentTarget.value = '';
      this.adjustmentIsValid = false;
      this.adjustmentValue = 0.00;
      this.adjustmentDisplayTarget.textContent = '???';
    } else {
      //this.adjustmentTarget.value = adjustmentAmount;
      this.adjustmentIsValid = true;
      this.adjustmentValue = parseFloat(adjustmentAmount);
      let plusOrNot = '';
      if (adjustmentAmount > 0.0) {
        plusOrNot = '+';  // negative sign gets inserted by currencyFormatter
      }
      this.adjustmentDisplayTarget.textContent = `${plusOrNot} ${this.currencyFormatter.format(adjustmentAmount)}`;

    }

    this.checkAll();
  }

  rollover() {
    console.log('it');
    //console.log(this.rolloverTarget.value);
    let rolloverAmount = this.rolloverTarget.value.trim(); //.replace(/[^\d.]/g, '');
    //let rolloverAmount = Number.parseFloat(trimmedrollover);
    console.log(rolloverAmount);
    if (rolloverAmount.startsWith('-')) {
      this.rolloverIsValid = false;
      this.rolloverValue = 0.00;
      this.rolloverDisplayTarget.textContent = '???';
    } else if (rolloverAmount.length === 0) {
      console.log('just white space');
      this.rolloverIsValid = true;
      this.rolloverValue = 0.00;
      this.rolloverDisplayTarget.textContent = this.currencyFormatter.format(rolloverAmount);
    } else if (isNaN(rolloverAmount)) {
      //this.rolloverTarget.value = '';
      this.rolloverIsValid = false;
      this.rolloverValue = 0.00;
      this.rolloverDisplayTarget.textContent = '???';
    } else {
      //this.rolloverTarget.value = rolloverAmount;
      this.rolloverIsValid = true;
      this.rolloverValue = parseFloat(rolloverAmount);
      this.rolloverDisplayTarget.textContent = this.currencyFormatter.format(rolloverAmount);

    }

    this.checkAll();
  }

  checkAll() {
    if ((this.adjustmentIsValid == true) && (this.rolloverIsValid == true)) {
      this.payoutIsValid = true;
      this.saveTarget.removeAttribute("disabled");
      let netNumber = parseFloat(this.netTarget.textContent);
      this.payoutValue = netNumber + this.adjustmentValue - this.rolloverValue;
      this.payoutDisplayTarget.textContent = this.currencyFormatter.format(this.payoutValue);
    } else {
      this.payoutIsValid = false;
      this.saveTarget.setAttribute("disabled", "disabled");
      this.payoutValue = 0.0;
      this.payoutDisplayTarget.textContent = '???';
    }
  }

}
