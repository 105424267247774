// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
  }

  connect() {
    console.log("Hello, Document Creation!", this.element)
  }

  static targets = [ "filechooser", "filename" ]

  chosen() {
    let filename = '';
    let files = this.filechooserTarget.files;
    // assuming we only upload one file at a time
    this.filenameTarget.textContent = files[0].name;
  }

}
