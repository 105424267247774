import { Controller } from "stimulus";

export default class extends Controller {

  initialize() {
    console.log("Hello - initialize modal-opener");
  }

  connect() {
    console.log("Hello, Modal Opener!", this.element)
  }

  static targets = ["modal"];

  launchDemo() {
    // not sure this is necessary - from https://tighten.co/blog/stimulus-101-building-a-modal/ 
    let modalController = this.application.getControllerForElementAndIdentifier(
      this.modalTarget,
      "modal"
    );
    console.log(modalController);
    modalController.open();
  }

  open() {
    this.modalTarget.classList.add('is-active');
  }

  close() {
    this.modalTarget.classList.remove('is-active');
  }

}