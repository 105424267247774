// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
     this.currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    this.numberFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
  }

  connect() {
    console.log("Hello, Project Creation!", this.element)
  }

  static targets = [ "total", "totalDisplay", "units", "unitsDisplay", "priceDisplay" ]

  tot() {
    let trimmedTotal = this.totalTarget.value.trim().replace(/[^\d.]/g, '');
    let totalAmount = Number.parseFloat(trimmedTotal);
    if (Number.isNaN(totalAmount)) {
      this.totalTarget.value = trimmedTotal;
      console.log('set total ', trimmedTotal);
      this.totalDisplayTarget.textContent = 'Invalid';
    } else {
      this.totalTarget.value = trimmedTotal; // valid number, so make sure to take out whitespace
      let formattedDisplay = this.currencyFormatter.format(totalAmount);
      this.totalDisplayTarget.textContent = `See if the total looks right: ${formattedDisplay}`;
    }

    // check if something was already entered for units and respond accordingly
    let numUnits = this.unitsTarget.value;
    let parsedNumber = parseFloat(numUnits);
    console.log('parsedNumber: ', parsedNumber);
    if (Number.isNaN(parsedNumber)) {
      // either blank or whitespace or letters so do nothing
    } else { // something was already entered for units so update price
      this.calculatePrice();
    }
  }

  calculatePrice() {
    let formattedDisplay = '';
    this.unitsDisplayTarget.textContent = ''; // just to initialize 
    let result;
    let totalAmount = this.totalTarget.value;
    let numberOfUnits = parseFloat(this.unitsTarget.value);
    console.log('calculatePrice parsed number of units: ', numberOfUnits);

    if (Number.isNaN(numberOfUnits)) {
      this.unitsTarget.value = '';
    } else {
      if ((this.unitsTarget.value.indexOf('.') != -1)) {
        numberOfUnits = numberOfUnits + 0.0;
        console.log('has period: ', numberOfUnits);
      }

      if (numberOfUnits < 0) {
        numberOfUnits = numberOfUnits * -1;
        console.log('negative, now: ', numberOfUnits);
      }

      if (numberOfUnits > 0) {
        console.log('calculatePrice, positive units ', numberOfUnits);
        this.unitsDisplayTarget.textContent = numberOfUnits;
        //this.unitsTarget.value = numberOfUnits; // causing problem, e.g. 1.05 not possible
        result = totalAmount / numberOfUnits;
        formattedDisplay = this.currencyFormatter.format(result);
        console.log(`We are in calculatePrice: ${numberOfUnits} - ${result}`);
      }
    }

    console.log('calculatePrice displaying: ', formattedDisplay);
    this.priceDisplayTarget.textContent = formattedDisplay;
  }
}
